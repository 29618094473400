<template>
  <div class="container">
    <div class="rings"></div>
  </div>
</template>

<style scoped>
.container {
  position: relative;
}

.rings,
.rings::before,
.rings::after {
  position: absolute;
  top: 50%;
  left: 50%;
  border: 1px solid rgb(204, 204, 204);
  border-left-color: rgb(82, 82, 82);
  border-radius: 649px;
  -o-border-radius: 649px;
  -ms-border-radius: 649px;
  -webkit-border-radius: 649px;
  -moz-border-radius: 649px;
}

.rings {
  margin: -16px 0 0 -16px;
  height: 32px;
  width: 32px;
  animation: rings-rotate 1150ms linear infinite;
  -o-animation: rings-rotate 1150ms linear infinite;
  -ms-animation: rings-rotate 1150ms linear infinite;
  -webkit-animation: rings-rotate 1150ms linear infinite;
  -moz-animation: rings-rotate 1150ms linear infinite;
}

.rings::before {
  content: "";
  margin: -15px 0 0 -15px;
  height: 29px;
  width: 29px;
  animation: rings-rotate 1150ms linear infinite;
  -o-animation: rings-rotate 1150ms linear infinite;
  -ms-animation: rings-rotate 1150ms linear infinite;
  -webkit-animation: rings-rotate 1150ms linear infinite;
  -moz-animation: rings-rotate 1150ms linear infinite;
}

.rings::after {
  content: "";
  margin: -13px 0 0 -13px;
  height: 24px;
  width: 24px;
  animation: rings-rotate 2300ms linear infinite;
  -o-animation: rings-rotate 2300ms linear infinite;
  -ms-animation: rings-rotate 2300ms linear infinite;
  -webkit-animation: rings-rotate 2300ms linear infinite;
  -moz-animation: rings-rotate 2300ms linear infinite;
}

@keyframes rings-rotate {
  100% {
    transform: rotate(360deg);
  }
}

@-o-keyframes rings-rotate {
  100% {
    -o-transform: rotate(360deg);
  }
}

@-ms-keyframes rings-rotate {
  100% {
    -ms-transform: rotate(360deg);
  }
}

@-webkit-keyframes rings-rotate {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-moz-keyframes rings-rotate {
  100% {
    -moz-transform: rotate(360deg);
  }
}
</style>