<template>
  <div class="root">
    <nav>
      <div class="name_c">
        <!-- <nuxt-image :src="logoSrc" v-if="logoSrc" /> -->
        <div class="name">{{ venueName }}</div>
      </div>

      <NuxtLink class="link home" :to="`/`">Home</NuxtLink>
      <NuxtLink class="link" :to="`/menu`">Menu</NuxtLink>
      <NuxtLink class="link" :to="store.order" v-if="store.order"  target="_blank"
        >Order Now</NuxtLink
      >
      <NuxtLink class="link" :to="store.reservation" v-if="store.reservation" target="_blank"
        >Reservation</NuxtLink
      >
      <NuxtLink class="link" :to="`/about`" v-if="store.aboutContent"
        >About</NuxtLink
      >

      <SocialStrip class="social" :size="20" />
    </nav>

    <div class="content">
      <slot />
    </div>

    <div class="watermark" v-if="!store.isPro">
      <div class="watermark_text">
        Powered by &nbsp;<NuxtLink
          class="fcp"
          to="https://www.foodcostprofiler.com"
          target="_blank"
          >Food Cost Profiler</NuxtLink
        >
      </div>
      <div>Watermark not shown for a Pro venue</div>
    </div>

    <div class="location">
      <DeliveryStrip
        v-if="store.delivery && store.delivery.length"
      ></DeliveryStrip>
      <div class="contact_c">
        <div class="hours_c line">
          <div class="day_c">
            <div class="day">Monday</div>
            <div class="hours">{{ hoursMonday }}</div>
          </div>
          <div class="day_c">
            <div class="day">Tuesday</div>
            <div class="hours">{{ hoursTuesday }}</div>
          </div>
          <div class="day_c">
            <div class="day">Wednesday</div>
            <div class="hours">{{ hoursWednesday }}</div>
          </div>
          <div class="day_c">
            <div class="day">Thursday</div>
            <div class="hours">{{ hoursThursday }}</div>
          </div>
          <div class="day_c">
            <div class="day">Friday</div>
            <div class="hours">{{ hoursFriday }}</div>
          </div>
          <div class="day_c">
            <div class="day">Saturday</div>
            <div class="hours">{{ hoursSaturday }}</div>
          </div>
          <div class="day_c">
            <div class="day">Sunday</div>
            <div class="hours">{{ hoursSunday }}</div>
          </div>
        </div>
        <div class="phone line" v-if="phone">
          <a :href="'tel:' + phone">{{ phone }}</a>
        </div>
        <div class="address line">{{ address }}</div>
        <div class="email line" v-if="email">
          <a :href="'mailto:' + email">{{ email }}</a>
        </div>

        <SocialStrip class="social" :size="20" color="grey" />
      </div>
      <div>
        <iframe
          :src="mapSrc"
          style="border: 0"
          loading="lazy"
          referrerpolicy="origin"
        ></iframe>
      </div>
    </div>

    <footer>
      Powered by &nbsp;
      <NuxtLink
        class="link"
        to="https://www.foodcostprofiler.com"
        target="_blank"
        >Food Cost Profiler</NuxtLink
      >
    </footer>

    <div class="splash" v-if="store.loading"><LoadingRings />Loading...</div>
    <div class="splash" v-else-if="!store.tag">Tag not found!</div>
  </div>
</template>

<script setup lang="ts">
const store = useStore();
const venueName = computed(() => store.profile && store.profile.venue_name);

useHead({
  title: store.tag,
});

//const testMap = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d196.98190453927515!2d144.96527401045867!3d-37.82025007947482!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad642b3da012771%3A0xed0bbc69f9682065!2sYASSAS%20THE%20GREEK%20WAY%20-%20SOUTHBANK!5e0!3m2!1sen!2sau!4v1689006767734!5m2!1sen!2sau";

const mapSrc = computed(() => (store.mapUrl ? store.mapUrl : ""));

const address = computed(() => {
  return `${store.profile?.address || ""}, ${store.profile?.city || ""}, ${
    store.profile?.state || ""
  }, ${store.profile?.postcode || ""}`;
});

const phone = computed(() => store.profile?.phone || "");
const email = computed(() => store.profile?.email || "");

const hoursMonday = computed(() => {
  if (store.profile?.publish?.hours?.monday) {
    let hours = store.profile.publish.hours.monday;
    if (!hours.open && !hours.close) return "Closed";
    else
      return `${hours.open ? hours.open : "-"} - ${
        hours.close ? hours.close : "-"
      }`;
  }
});

const hoursTuesday = computed(() => {
  if (store.profile?.publish?.hours?.tuesday) {
    let hours = store.profile.publish.hours.tuesday;
    if (!hours.open && !hours.close) return "Closed";
    else
      return `${hours.open ? hours.open : "-"} - ${
        hours.close ? hours.close : "-"
      }`;
  }
});

const hoursWednesday = computed(() => {
  if (store.profile?.publish?.hours?.wednesday) {
    let hours = store.profile.publish.hours.wednesday;
    if (!hours.open && !hours.close) return "Closed";
    else
      return `${hours.open ? hours.open : "-"} - ${
        hours.close ? hours.close : "-"
      }`;
  }
});

const hoursThursday = computed(() => {
  if (store.profile?.publish?.hours?.thursday) {
    let hours = store.profile.publish.hours.thursday;
    if (!hours.open && !hours.close) return "Closed";
    else
      return `${hours.open ? hours.open : "-"} - ${
        hours.close ? hours.close : "-"
      }`;
  }
});

const hoursFriday = computed(() => {
  if (store.profile?.publish?.hours?.friday) {
    let hours = store.profile.publish.hours.friday;
    if (!hours.open && !hours.close) return "Closed";
    else
      return `${hours.open ? hours.open : "-"} - ${
        hours.close ? hours.close : "-"
      }`;
  }
});

const hoursSaturday = computed(() => {
  if (store.profile?.publish?.hours?.saturday) {
    let hours = store.profile.publish.hours.saturday;
    if (!hours.open && !hours.close) return "Closed";
    else
      return `${hours.open ? hours.open : "-"} - ${
        hours.close ? hours.close : "-"
      }`;
  }
});

const hoursSunday = computed(() => {
  if (store.profile?.publish?.hours?.sunday) {
    let hours = store.profile.publish.hours.sunday;
    if (!hours.open && !hours.close) return "Closed";
    else
      return `${hours.open ? hours.open : "-"} - ${
        hours.close ? hours.close : "-"
      }`;
  }
});
</script>

<style scoped>
.root {
  height: 100vh;
  width: 100vw;
  overflow-y: auto;
}

nav {
  display: flex;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px 10px;
}

.content {
  min-height: calc(100vh - 635px);
}

.splash {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  font-size: x-small;
  letter-spacing: 10px;
  color: #404040;
  display: flex;
  gap: 40px;
  align-items: center;
  justify-content: center;
  background-color: white;
}

.location {
  display: flex;
  align-items: stretch;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;
  font-size: small;
  padding: 20px;
}

.location .contact_c {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #dfdfdf;
  color: #404040;
  padding: 20px 40px;
}

.contact_c .phone {
  font-size: large;
}

.contact_c a {
  color: #404040;
  text-decoration: none;
}

.hours_c .day_c {
  display: flex;
  font-size: small;
}

.hours_c .day {
  width: 80px;
  text-align: end;
  margin-right: 10px;
}

.location iframe {
  height: 500px;
}

.name {
  font-size: 1.5rem;
  font-weight: bold;
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.name_c {
  margin-right: auto;
}

.name_c .name {
  font-family: "Nunito";
  font-size: x-large;
  letter-spacing: 1px;
}

.link {
  align-self: center;
  text-decoration: none;
  font-size: small;
  font-weight: 400;
  color: white;
  margin-right: 10px;
}

.line {
}

.line::after {
  align-self: center;
  display: flex;
  content: "";
  height: 1px;
  width: 120px;
  background-image: linear-gradient(
    to right,
    transparent,
    #666 75%,
    transparent
  );
  margin: 20px auto;
}

.social svg {
  height: 20px;
  width: 20px;
}

footer {
  grid-area: footer;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: #303030;
  color: white;
  font-size: x-small;
  padding: 10px 30px;
}

footer .link {
  text-decoration: none;
  font-size: x-small;
}

.watermark {
  position: fixed;
  bottom: 50%;
  right: 0;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  font-size: x-small;
  text-align: center;
  letter-spacing: 2px;
  opacity: 0;
  animation: fadeIn 10s;
  animation-delay: 2s;
  animation-fill-mode: forwards;
  padding: 20px;
}
.watermark .watermark_text {
  font-size: x-large;
  font-weight: bold;
}

.watermark .fcp {
  color: white;
  text-decoration: none;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* TODO: Layout for mobile! */

@media screen and (max-width: 767px) {
  nav {
    display: flex;
    flex-wrap: wrap;
  }

  .name_c {
    width: 100%;
  }
  .name {
    margin-top: 0;
  }

  .link.home {
    margin-left: auto;
  }

.content {
  min-height: calc(100vh - 635px);
}

  .location {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    flex-wrap: wrap;
    font-size: small;
    padding: 20px;
  }
}

@media screen and (max-width: 400px) {
  nav .social {
    display: none;
  }
}
</style>
